import React, {useEffect, useState} from 'react';

import './App.css';

function App() {
  const [showAfuera, setShowAfuera] = useState(false);
  const [showMinisterio, setShowMinisterio] = useState(false);
  const [allowRestart, setAllowRestart] = useState(false);
  const [ministerio, setMinisterio] = useState('');
  const [ministerium, setMinisterium] = useState('');
  const [listMinisterio, setListMinisterio] = useState<string[]>([]);
  useEffect(() => {
        fetch('ministerios.txt')
            .then((r) => r.text())
            .then(text  => {
                let listMinisterio = text.split("\n");
                listMinisterio.forEach(function (value, index, array) {
                    array[index] = value.trim()
                });
                setListMinisterio(listMinisterio);
                setMinisterio(listMinisterio[0])
            });
  }, []);
  useEffect(function () {
      if (showMinisterio) {
          if (window['speechSynthesis'] === undefined) {
              setTimeout(function () {
                  setShowMinisterio(false);
                  setShowAfuera(true);
              }, 2500);
          } else {
              let msg = new SpeechSynthesisUtterance();
              msg.text = ministerio;
              msg.lang = "es"
              msg.onend = function () {
                  setShowMinisterio(false);
                  setShowAfuera(true);
              };
              window.speechSynthesis.speak(msg);
          }
      }
  }, [ministerio]);
    useEffect(() => {
        if (showAfuera) {
            var aud = new Audio("afuera.mp3");
            aud.onended = function () {
                setAllowRestart(true);
            };
            aud.onerror = function () {
                setTimeout(function () {
                    setShowAfuera(false);
                    setShowMinisterio(true);
                    rerollMst();
                }, 2500);
            };
            aud.play();
        }
    }, [showAfuera]);
    function inicio() {
        setShowMinisterio(true);
        rerollMst();
    }
    function restart() {
        if (allowRestart) {
            setAllowRestart(false);
            setShowAfuera(false);
            setShowMinisterio(true);
            rerollMst();
        }
    }
    function rerollMst() {
        let line = listMinisterio[Math.floor(Math.random() * listMinisterio.length)];
        setMinisterio(line.split(" - ")[0]);
        setMinisterium(line.split(" - ")[1]);
    }
  return (
    <div onClick={restart}>
      {showAfuera && <img className={"milei-afuera"} src="afuera.png" alt={"Presidente Milei"}></img>}
      {showMinisterio && <div className={"ministerio"}>{ministerio}<br /><hr />{ministerium}</div>}
      {(!showAfuera && !showMinisterio) &&
          <div className={"btn-container"}>
              <button className={"start"} onClick={inicio}>Vota para Presidente Milei 2023</button>
          </div>
      }
    </div>
  );
}

export default App;
